import { useCallback } from 'react';

import { RMAvatar } from '@/components/RMAvatar/RMAvatar';
import { RMInput } from '@/components/RMInput/RMInput';
import { RMInputMasked } from '@/components/RMInputMasked/RMInputMasked';
import { RMLink } from '@/components/RMLink/RMLink';
import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { InputContainer, InputController } from '@/modules/form/container';
import { captureException } from '@/utils/captureException';
import { FilestackHandle, openFilestackPicker } from '@/utils/filestack';

import { ProfileEditForm } from '../../forms/profile-edit.form';

import { Container, Form, Header, PhoneNumberInputContainer } from './ProfileEdit.styles';

interface ProfileEditProps {
  profileNameInitials: string;
  photoUrl?: string | null;
  showEmail: boolean;
  form: ProfileEditForm;
  onPhotoChange: (file: FilestackHandle) => void;
}

export function ProfileEdit({ profileNameInitials, photoUrl, showEmail, form, onPhotoChange }: ProfileEditProps) {
  const isMobile = useIsMobileViewport();

  const handleOpenPhotoPicker = useCallback(async () => {
    try {
      await openFilestackPicker({
        accept: ['image/png', 'image/jpeg', 'image/webp'],
        maxSize: 1024 * 1024 * 20,
        onUploadDone: ({ filesUploaded }) => {
          if (!filesUploaded[0]) {
            return;
          }

          onPhotoChange(filesUploaded[0]);
        },
      });
    } catch (error) {
      captureException(error, true);
    }
  }, [onPhotoChange]);

  return (
    <Container data-mobile={isMobile}>
      <Header>
        <RMAvatar src={photoUrl} placeholder={profileNameInitials} size="large" background="blend" shape="circle" />
        <RMLink onClick={handleOpenPhotoPicker}>Change profile picture</RMLink>
      </Header>

      <Form>
        <InputContainer form={form} path="firstName">
          {(props) => <RMInput id="firstName" label="First name" {...props} />}
        </InputContainer>

        <InputContainer form={form} path="lastName">
          {(props) => <RMInput id="lastName" label="Last name" {...props} />}
        </InputContainer>

        {showEmail && (
          <InputContainer form={form} path="email">
            {(props) => <RMInput id="email" label="Email" {...props} />}
          </InputContainer>
        )}

        <InputController form={form} path="phone">
          {(props) => (
            <PhoneNumberInputContainer>
              <RMInputMasked
                id="host-phone"
                label="Phone number"
                placeholder="(XXX) XXX-XXXX"
                mask="(000) 000-0000"
                autoCapitalize="none"
                inputMode="tel"
                value={props.value ?? ''}
                error={props.error}
                unmask={true}
                onBlur={props.onBlur}
                onAccept={(unmaskedValue: string) => {
                  if (unmaskedValue !== props.value) {
                    props.onChange(unmaskedValue);
                  }
                }}
              />
              <RMText type="sans" size="xxs" color="on-surface-tertiary">
                Standard text message rates may apply. Unsubscribe any time by messaging STOP or disabling in your
                account settings. We accept USA and Canada phone numbers.
              </RMText>
            </PhoneNumberInputContainer>
          )}
        </InputController>
      </Form>
    </Container>
  );
}
