import { PropsWithChildren } from 'react';
import { faClose } from '@fortawesome/pro-regular-svg-icons';
import { AnimatePresence } from 'framer-motion';

import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';

import { RMBackButton } from '../RMBackButton/RMBackButton';
import { RMIconButton } from '../RMIconButton/RMIconButton';
import { RMText } from '../RMText/RMText';

import { CloseIconContainer, Container, ContentTransition } from './RMDialogPanelTitle.styles';
import { DialogPanelTransitions } from './RMDialogPanelTransition';

export type RMDialogPanelTitleProps = PropsWithChildren<{
  direction?: 'ltr' | 'rtl';
  back?: boolean;
  onBack?: () => void;
  onClose?: () => void;
}>;

export function RMDialogPanelTitle({ direction = 'rtl', back, onBack, onClose, children }: RMDialogPanelTitleProps) {
  const isMobile = useIsMobileViewport();

  return (
    <Container data-mobile={isMobile}>
      <div style={{ position: 'relative', flex: 1 }}>
        <AnimatePresence initial={false}>
          <ContentTransition
            key={String(back)}
            initial={{ ...DialogPanelTransitions[direction].initial, top: '50%', translateY: '-50%' }}
            animate={{ ...DialogPanelTransitions[direction].animate, top: '50%', translateY: '-50%' }}
            exit={{ ...DialogPanelTransitions[direction].exit, top: '50%', translateY: '-50%' }}
            data-mobile={isMobile}
          >
            {back ? <RMBackButton onClick={onBack} /> : null}
            {!back && typeof children !== 'string' ? children : null}
            {!back && typeof children === 'string' ? (
              <RMText
                type={isMobile ? 'sans' : 'serif'}
                size={isMobile ? 'm' : 'xl'}
                bold={isMobile}
                color="on-surface-primary"
              >
                {children}
              </RMText>
            ) : null}
          </ContentTransition>
        </AnimatePresence>
      </div>
      <CloseIconContainer>
        <RMIconButton
          icon={faClose}
          tooltip={null}
          outline
          backgroundColor="transparent"
          stateColor="darken-primary"
          onClick={onClose}
        />
      </CloseIconContainer>
    </Container>
  );
}
