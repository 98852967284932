import { useCallback, useEffect, useMemo, useState } from 'react';
import { AssetType } from '@remento/types/asset';
import { EntityType } from '@remento/types/entity';
import { ConflictError, ConflictErrorType, InvalidRequestError } from '@remento/types/error';
import { UserStatus } from '@remento/types/user';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMConfirmationModal } from '@/components/RMConfirmationModal';
import { RMDialogPanel } from '@/components/RMDialogPanel';
import { toast } from '@/components/RMToast/RMToast';
import { createProfileEditForm } from '@/modules/account-settings/forms/profile-edit.form';
import { getFormValue, isFormDirty, isFormValid } from '@/modules/form/form';
import { setInputValue } from '@/modules/form/input';
import { useServices } from '@/Services';
import { EntityMutation } from '@/services/api/cache';
import { usePersonAvatarUrl, usePersonInitials, usePersonQuery } from '@/services/api/person';
import { usePersonUserQuery } from '@/services/api/user/user.service.hook';
import { captureException } from '@/utils/captureException';
import { FilestackHandle } from '@/utils/filestack';
import { genitiveCase } from '@/utils/genitiveCase';

import { ProfileEdit } from '../components/ProfileEdit/ProfileEdit';

export interface ProfileEditContainerProps {
  open: boolean;
  personId?: string | null;
  isStoryteller: boolean;
  onClose: () => void;
}

export function ProfileEditContainer({ open, personId, isStoryteller, onClose }: ProfileEditContainerProps) {
  const [form, setForm] = useState(() => createProfileEditForm());
  const { assetService, entityCacheManagerService, personService, userService } = useServices();

  const personQuery = usePersonQuery(open ? personId : null);
  const personName = personQuery.data?.name?.first;
  const personInitials = usePersonInitials(personQuery.data);
  const avatarUrl = usePersonAvatarUrl(personQuery.data);

  const userQuery = usePersonUserQuery(personId);
  const email = useMemo(() => userQuery.data?.communicationChannels.email ?? null, [userQuery]);
  const phone = useMemo(() => userQuery.data?.communicationChannels.phone ?? null, [userQuery]);

  const [changedPhoto, setChangedPhoto] = useState<{ url: string; handle: string } | null>(null);

  const closeAndResetDialog = useCallback(() => {
    onClose();
    setChangedPhoto(null);
    setForm(createProfileEditForm());
  }, [onClose]);

  const handlePhotoChange = useCallback((photo: FilestackHandle) => {
    setChangedPhoto(photo);
    toast('Photo uploaded', 'dialog-panel-toast');
  }, []);

  const handleSave = useCallback(async () => {
    const formValues = getFormValue(form);
    if (!isFormValid(form) || formValues == null) {
      return;
    }

    try {
      if (!personQuery.data || !userQuery.data) {
        console.warn('Person or user not loaded yet');
        return;
      }

      const person = personQuery.data;
      const user = userQuery.data;
      const mutations: EntityMutation[] = [];

      // Update photo
      if (changedPhoto) {
        const newAsset = await assetService.createFilestackAsset({
          entity: { id: person.id, type: EntityType.PERSON },
          type: AssetType.IMAGE,
          handle: changedPhoto.handle,
        });

        mutations.push(...personService.createSetAvatarImageMutation(person, newAsset.id));
      }

      mutations.push(
        ...personService.createSetPersonNameMutation(person, {
          first: formValues.firstName,
          last: formValues.lastName,
        }),
      );

      if (userQuery.data?.status === UserStatus.PLACEHOLDER) {
        mutations.push(...userService.createSetUserEmailMutation(user, formValues.email));
      }

      mutations.push(
        ...userService.createSetUserPhoneMutation(
          user,
          !formValues.phone || formValues.phone.length == 0
            ? null
            : {
                countryCode: '1',
                number: formValues.phone,
              },
        ),
      );

      // Run mutations
      await entityCacheManagerService.mutate(mutations);
      await entityCacheManagerService.invalidateEntity(EntityType.USER, user.personId);

      closeAndResetDialog();
      toast('Profile updated');
    } catch (error) {
      console.log(error);
      if (
        error instanceof InvalidRequestError ||
        (error instanceof ConflictError && error.data?.type === ConflictErrorType.USER_ALREADY_EXISTS)
      ) {
        toast(
          <>
            A user with that email address already exist. Please reach out to{' '}
            <a href="mailto:support@remento.co">support</a>.
          </>,
          'dialog-panel-toast',
          'error',
        );
      } else {
        captureException(error, true);
        toast('Failed to update profile', 'dialog-panel-toast', 'error');
      }
    }
  }, [
    form,
    personQuery.data,
    userQuery.data,
    changedPhoto,
    personService,
    userService,
    entityCacheManagerService,
    closeAndResetDialog,
    assetService,
  ]);

  const [closeConfirmationOpen, setCloseConfirmationOpen] = useState(false);

  const handleSaveChanges = useCallback(() => {
    setCloseConfirmationOpen(false);
    handleSave();
  }, [handleSave]);

  const handleDiscardChanges = useCallback(() => {
    setCloseConfirmationOpen(false);
    closeAndResetDialog();
  }, [closeAndResetDialog]);

  const handleClose = useCallback(() => {
    // If we have pending changes, ask the user if they want to discard
    if (changedPhoto || isFormDirty(form)) {
      setCloseConfirmationOpen(true);
      return;
    }
    // otherwise, just close the dialog directly
    closeAndResetDialog();
  }, [changedPhoto, form, closeAndResetDialog]);

  // Update the form value
  useEffect(() => {
    if (!personQuery.data) {
      return;
    }
    setInputValue(form, 'firstName', personQuery.data.name?.first ?? '');
    setInputValue(form, 'lastName', personQuery.data.name?.last ?? '');
    setInputValue(form, 'email', email ?? '');
    setInputValue(form, 'phone', phone?.number ?? '');
  }, [email, form, personQuery.data, phone]);

  const photoSrc = changedPhoto?.url ?? avatarUrl;

  return (
    <RMDialogPanel.Root open={open} onClose={handleClose}>
      <RMDialogPanel.Title onClose={handleClose}>
        {isStoryteller ? `${genitiveCase(personName ?? '')} profile` : 'My profile'}
      </RMDialogPanel.Title>
      <RMDialogPanel.Content>
        {personQuery.data && (personQuery.data?.avatarAssetId == null || avatarUrl != null) ? (
          <ProfileEdit
            form={form}
            photoUrl={photoSrc}
            profileNameInitials={personInitials}
            showEmail={userQuery.data?.status === UserStatus.PLACEHOLDER}
            onPhotoChange={handlePhotoChange}
          />
        ) : null}
      </RMDialogPanel.Content>
      <RMDialogPanel.Actions>
        <RMButton background="primary" size="large" fullWidth onClick={handleSave} autoLoading>
          Save
        </RMButton>
      </RMDialogPanel.Actions>

      <RMConfirmationModal
        open={closeConfirmationOpen}
        type="primary"
        title="Save changes?"
        message="You have unsaved changes on this page. Would you like to save them?"
        confirmLabel="Save changes"
        cancelLabel="Discard"
        onConfirm={handleSaveChanges}
        onCancel={handleDiscardChanges}
      />
    </RMDialogPanel.Root>
  );
}
