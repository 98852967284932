import { styled } from '@linaria/react';
import { motion } from 'framer-motion';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing-xs);
  padding: var(--spacing-2xl) var(--spacing-3xl);
  border-bottom: 1px solid var(--border-hairline);
  position: relative;
  overflow: hidden;

  font-family: var(--font-serif);
  font-size: 31px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;

  &[data-mobile='true'] {
    font-size: 25px;
    padding: var(--spacing-sm) var(--spacing-md);
  }
`;

export const ContentTransition = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-50%);
  top: 50%;
  width: 100%;
  z-index: 0;
`;

export const CloseIconContainer = styled.div`
  margin-left: auto;
  z-index: 1;
  background-color: var(--surface);
`;
